/* eslint-disable perfectionist/sort-imports */
import './plugins/assets';

import { createApp } from 'vue';

import App from './App.vue';
import {
  initAmplify,
  renderAppLoader,
  setupNProgress,
  setupVueQuery,
} from './plugins';
import { setupI18n } from './locales';
import { setupRouter } from './router';
import { setupStore } from './store';

(async () => {
  // app loading
  renderAppLoader();

  // progress bar
  setupNProgress();

  const app = createApp(App);

  // Store
  setupStore(app);

  // This needs to be after setup store to use the auth's store
  await initAmplify();

  // Vue Query
  setupVueQuery(app);

  await setupI18n(app);

  // Init Router
  await setupRouter(app);

  // mount app
  app.mount('#app');
})();
