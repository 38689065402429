import { Amplify } from 'aws-amplify';

import { useAuthStore } from '~~/auth/auth.store';

/**
 * This relative import is necessary since it's living in the root of the project.
 * TODO: There might be a better way to handle this.
 */
import outputs from '../../amplify_outputs.json';

export async function initAmplify() {
  Amplify.configure(outputs);

  const authStore = useAuthStore();

  await authStore.populateCurrentUser();

  const existingConfig = Amplify.getConfig();

  Amplify.configure({
    ...existingConfig,
    API: {
      REST: {
        ...outputs.custom.API,
        // @ts-expect-error - not sure why the type does not match
        headers: async () => {
          return { Authorization: authStore.idToken };
        },
      },
    },
  });

  /**
   * We need to fetch this after the amplify configure above.
   * Since the `idToken` is needed and it is being fetched by `populateCurrentUser`.
   */
  await authStore.getSnowflakeToken();
}
