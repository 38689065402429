import type { LocationQueryRaw, RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router';

import { useAuthStore } from '~~/auth/auth.store';
import { AUTH_ROUTE_NAME, NOT_FOUND_ROUTE_NAME } from '~~/router/router.entity';
import { useRouterStore } from '~~/router/router.store';

interface StrategicPattern {
  /** If the condition is true, then call the action function */
  callback: () => void;
  /** The condition */
  condition: boolean;
}

export function createRouteGuard(router: Router) {
  router.beforeEach(async (to, _from, next) => {
    const location = await initRoute(to);

    if (location) {
      next(location);
      return;
    }

    const authStore = useAuthStore();

    const hasLoggedIn = Boolean(authStore.currentUser);
    const needLogin = !to.meta.ignoreAuth;

    const routeSwitches: Array<StrategicPattern> = [
      // if it is login route when logged in, then switch to the root page
      {
        condition: hasLoggedIn && to.matched.some((match) => match.name === AUTH_ROUTE_NAME),
        callback: () => {
          next({ name: 'Root' });
        },
      },
      // if is is constant route, then it is allowed to access directly
      {
        condition: !needLogin,
        callback: () => {
          next();
        },
      },
      // if the route need login but the user is not logged in, then switch to the login page
      {
        condition: !hasLoggedIn && needLogin,
        callback: () => {
          next({ name: AUTH_ROUTE_NAME, query: { redirect: to.fullPath } });
        },
      },
      // if the user is logged in and has authorization, then it is allowed to access
      {
        condition: hasLoggedIn && needLogin,
        callback: () => {
          next();
        },
      },
      // if the user is logged in but does not have authorization, then switch to the 403 page
      {
        condition: hasLoggedIn && needLogin,
        callback: () => {
          next({ name: NOT_FOUND_ROUTE_NAME });
        },
      },
    ];

    routeSwitches.some(({ condition, callback }) => {
      if (condition) {
        callback();
      }

      return condition;
    });
  });
}

/**
 * initialize route
 */
async function initRoute(to: RouteLocationNormalized): Promise<RouteLocationRaw | null> {
  const routerStore = useRouterStore();
  const authStore = useAuthStore();

  const isNotFoundRoute = to.name === NOT_FOUND_ROUTE_NAME;

  // if the constant route is not initialized, then initialize the constant route
  if (!routerStore.hasConstantRoutesAdded) {
    await routerStore.initConstantRoutes();

    /**
     * the route is captured by the `not-found` route because the constant route is not initialized.
     * after the constant route has initialized, redirect to the original route
     */

    if (isNotFoundRoute) {
      const path = to.fullPath;

      const location: RouteLocationRaw = {
        path,
        replace: true,
        query: to.query,
        hash: to.hash,
      };

      return location;
    }
  }

  // if the route is the `ignoreAuth` route but is not the "not-found" route, then it is allowed to access.
  if (to.meta.ignoreAuth && !isNotFoundRoute) {
    return null;
  }

  // the auth route has initialized then it is allowed to access
  if (routerStore.hasAuthRoutesAdded) {
    return null;
  }

  const hasLoggedIn = Boolean(authStore.currentUser);

  // initializing the auth route requires the user to be logged in, if not, redirect to the login page

  if (!hasLoggedIn) {
    const redirect = to.fullPath;

    const query: LocationQueryRaw = to.name !== AUTH_ROUTE_NAME ? { redirect } : {};

    const location: RouteLocationRaw = {
      name: AUTH_ROUTE_NAME,
      query,
    };

    return location;
  }

  // initialize the auth route
  await routerStore.initAuthRoutes();

  // the route is captured by the "not-found" route because the auth route is not initialized
  // after the auth route is initialized, redirect to the original route

  if (isNotFoundRoute) {
    const path = to.redirectedFrom?.name === 'Root' ? '/' : to.fullPath;

    const location: RouteLocationRaw = {
      path,
      replace: true,
      query: to.query,
      hash: to.hash,
    };

    return location;
  }

  return null;
}
