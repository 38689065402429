import type { AuthUser } from 'aws-amplify/auth';

import { get } from 'aws-amplify/api';
import { fetchAuthSession, getCurrentUser, signIn, signOut } from 'aws-amplify/auth';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { router } from '~~/router';
import { useNavigation } from '~~/router/composables/use-navigation';
import { useRouterStore } from '~~/router/router.store';
import { SETUP_STORE_ID } from '~~/store/store.entity';

export const useAuthStore = defineStore(SETUP_STORE_ID.AUTH, () => {
  const currentUser = ref<AuthUser | null>(null);
  /**
   * idToken is used to authenticate with the lambda function
   */
  const idToken = ref<null | string>(null);

  // To authenticate with snowflake
  const snowflakeToken = ref<null | string>(null);
  const routerStore = useRouterStore();

  const { redirectFromLogin } = useNavigation(false);

  async function populateCurrentUser() {
    const hasToken = await setIdToken();

    if (!hasToken) {
      return;
    }

    currentUser.value = await getCurrentUser();
  }

  async function setIdToken() {
    const session = await fetchAuthSession();

    if (!session.tokens?.idToken) {
      return false;
    }

    idToken.value = session.tokens.idToken.toString();

    return true;
  }

  async function getSnowflakeToken() {
    if (!idToken.value) {
      return;
    }

    const { body } = await get({
      apiName: 'dcHttpApi',
      path: 'snowflake-auth',
      options: {
        headers: {
          Authorization: idToken.value!,
        },
      },
    })
      .response;

    /**
     * TODO: Not sure why we can't define the typings for the response
     */
    const data = await body.json() as any;

    snowflakeToken.value = data.token;
  }

  async function loginUser(
    { email, password, redirect = true }:
    { email: string; password: string; redirect?: boolean },
  ): Promise<void> {
    try {
      await signIn({
        username: email,
        password,
      });

      await populateCurrentUser();

      await routerStore.initAuthRoutes();

      if (redirect) {
        await redirectFromLogin();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function logout() {
    await signOut();

    currentUser.value = null;
    idToken.value = null;
    snowflakeToken.value = null;

    await router.replace('/auth/login');
  }

  return {
    currentUser,
    idToken,
    snowflakeToken,
    populateCurrentUser,
    getSnowflakeToken,
    setIdToken,
    loginUser,
    logout,
  };
});
