import type { App } from 'vue';

import queryString from 'query-string';
import {
  createRouter,
  createWebHistory,
} from 'vue-router';

import { useRouterStore } from '~~/router/router.store';

import { createRouterGuard } from './guard';
import { BUILTIN_ROUTES } from './routes/builtin.routes';

export const router = createRouter({
  history: createWebHistory(),
  routes: BUILTIN_ROUTES,
  scrollBehavior: () => ({ left: 0, top: 0 }),
  strict: true,
  // @ts-expect-error - not sure how to properly satisfy the typings
  parseQuery: (query) => {
    return queryString.parse(query, {
      arrayFormat: 'bracket',
      parseBooleans: true,
    });
  },
  stringifyQuery: (query) => {
    return queryString.stringify(query, {
      arrayFormat: 'bracket',
      strict: true,
      skipNull: true,
      skipEmptyString: true,
    });
  },
});

/** Setup Vue Router */
export async function setupRouter(app: App) {
  app.use(router);

  createRouterGuard(router);

  await router.isReady();

  // TODO: remove this when authentication has been implemented
  const routerStore = useRouterStore();
  await routerStore.initAuthRoutes();
}
