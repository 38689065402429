<script lang="ts" setup>
import { computed, useAttrs } from 'vue';

/**
 * Props
 *
 * - Support iconify and local svg icon
 * - If icon and localIcon are passed at the same time, localIcon will be rendered first
 */
interface Props {
  /** Iconify icon name */
  icon?: string;
  /** Local svg icon name */
  localIcon?: string;
}

defineOptions({ name: 'CoreSvgIcon', inheritAttrs: false });

const props = defineProps<Props>();

const attrs = useAttrs();

const bindAttrs = computed<{ class: string; style: string }>(() => ({
  class: (attrs.class as string) || '',
  style: (attrs.style as string) || '',
}));

const symbolId = computed(() => {
  const defaultLocalIcon = 'no-icon';

  const icon = props.localIcon || defaultLocalIcon;

  return `#icon-local-${icon}`;
});

/** If localIcon is passed, render localIcon first */
const renderLocalIcon = computed(() => props.localIcon || !props.icon);
</script>

<template>
  <svg
    v-if="renderLocalIcon"
    aria-hidden="true"
    width="1em"
    height="1em"
    v-bind="bindAttrs"
  >
    <use
      :xlink:href="symbolId"
      fill="currentColor"
    />
  </svg>

  <i
    v-else-if="icon"
    :class="icon"
    v-bind="bindAttrs"
  />
</template>
