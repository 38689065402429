import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT, PAGES_MODULES } from '~~/router/router.entity';

const activityRoute: RouteRecordRaw = {
  path: `${PAGES_MODULES.ENGAGEMENT}/activities`,
  name: '/activities-root',
  component: LAYOUT_DEFAULT,
  children: [
    {
      path: '',
      name: `${PAGES_MODULES.ENGAGEMENT}/activities`,
      component: () => import('~~/pages/engagements/activities/index.vue'),
      meta: {
        i18nKey: 'routes.activities.index',
        icon: 'icon-solar:transfer-horizontal-linear',
      },
    },
    {
      path: 'create',
      name: `${PAGES_MODULES.ENGAGEMENT}/activities/create`,
      component: () => import('~~/pages/engagements/activities/create.vue'),
      meta: {
        i18nKey: 'routes.activities.create',
      },
    },
  ],
};

export default activityRoute;
