import { useLocalStorage, usePreferredColorScheme } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, effectScope, ref, toRefs, watch } from 'vue';

import { addThemeVarsToHtml, createThemeToken, getNaiveTheme } from '~~/designs/theme/theme.util';
import { SETUP_STORE_ID } from '~~/store/store.entity';

import type { KuliTheme } from './theme.types';

import { BACKUP_BEFORE_MOBILE_KEY, DEFAULT_THEME, MIXED_SIDEBAR_FIXED_KEY } from './theme.entity';

export const backupThemeBeforeMobileLocalStorage = useLocalStorage<{
  layout: KuliTheme['ThemeLayoutMode'];
  sidebarCollapse: boolean;
}>(BACKUP_BEFORE_MOBILE_KEY, null);

export const isMixSidebarFixedLocalStorage = useLocalStorage<boolean>(MIXED_SIDEBAR_FIXED_KEY, false);

export const useThemeStore = defineStore(SETUP_STORE_ID.THEME, () => {
  const scope = effectScope();
  const osTheme = usePreferredColorScheme();

  /** Theme settings */
  const settings = ref<KuliTheme['ThemeSetting']>(DEFAULT_THEME);

  /** Dark mode */
  const isDarkMode = computed(() => {
    if (settings.value.themeScheme === 'auto') {
      return osTheme.value === 'dark';
    }
    return settings.value.themeScheme === 'dark';
  });

  /** Theme colors */
  const themeColors = computed(() => {
    const { isInfoFollowPrimary, otherColor, themeColor } = settings.value;
    const colors: KuliTheme['ThemeColor'] = {
      primary: themeColor,
      ...otherColor,
      info: isInfoFollowPrimary ? themeColor : otherColor.info,
    };

    return colors;
  });

  /** Naive theme */
  const naiveTheme = computed(() =>
    getNaiveTheme(themeColors.value),
  );

  /** Reset store */
  function resetStore() {
    const themeStore = useThemeStore();

    themeStore.$reset();
  }

  /** Setup theme vars to html */
  function setupThemeVarsToHtml() {
    const { darkThemeTokens, themeTokens } = createThemeToken(themeColors.value);
    addThemeVarsToHtml(themeTokens, darkThemeTokens);
  }

  function setThemeLayout(mode: KuliTheme['ThemeLayoutMode']) {
    settings.value.layout.mode = mode;
  }

  function updateThemeColors(key: KuliTheme['ThemeColorKey'], color: string) {
    if (key === 'primary') {
      settings.value.themeColor = color;
    } else {
      settings.value.otherColor[key] = color;
    }
  }

  function setThemeScheme(themeScheme: KuliTheme['ThemeScheme']) {
    settings.value.themeScheme = themeScheme;
  }

  // watch store
  scope.run(() => {
    watch(
      themeColors,
      () => {
        setupThemeVarsToHtml();
      },
      { immediate: true },
    );
  });

  return {
    ...toRefs(settings.value),
    isDarkMode,
    naiveTheme,
    resetStore,
    setThemeLayout,
    updateThemeColors,
    themeColors,
    setThemeScheme,
  };
});
