import type { RouteRecordRaw } from 'vue-router';

import { EXCEPTION_COMPONENT, NOT_FOUND_ROUTE_NAME, ROOT_ROUTE_NAME } from '~~/router/router.entity';
import engagementRoute from '~~/router/routes/modules/engagements/routes.egmt';

export const ROOT_ROUTE: RouteRecordRaw = {
  name: ROOT_ROUTE_NAME,
  path: '/',
  component: () => import('~~/pages/index.vue'),
  meta: {
    title: 'RootPage',
    i18nKey: 'routes.Root',
  },
};

const NOT_FOUND_ROUTE: RouteRecordRaw = {
  name: NOT_FOUND_ROUTE_NAME,
  path: '/:path(.*)*',
  component: EXCEPTION_COMPONENT,
  meta: {
    title: 'ErrorPage',
    i18nKey: 'routes.error',
  },
};

export const BUILTIN_ROUTES: Array<RouteRecordRaw> = [
  ROOT_ROUTE,
  NOT_FOUND_ROUTE,
  engagementRoute,
];
