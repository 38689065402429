import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT, PAGES_MODULES } from '~~/router/router.entity';

const dashboardRoute: RouteRecordRaw = {
  path: `${PAGES_MODULES.ENGAGEMENT}/dashboard`,
  name: '/dashboard-root',
  component: LAYOUT_DEFAULT,
  meta: {
    icon: 'icon-mdi:monitor-dashboard',
    order: 1,
    i18nKey: 'routes.dashboard',
    title: 'Dashboard',
  },
  children: [
    {
      path: '',
      name: `${PAGES_MODULES.ENGAGEMENT}/dashboard`,
      component: () => import('~~/pages/engagements/dashboard/index.vue'),
      meta: {
        title: 'dashboard',
        hideInMenu: true,
      },
    },
  ],
};

export default dashboardRoute;
