export enum PAGES_MODULES {
  ENGAGEMENT = '/engagement',
}

export const ROOT_ROUTE_NAME = 'Root';

export const NOT_FOUND_ROUTE_NAME = 'RouteNotFound';

export const AUTH_ROUTE_NAME = 'RouteAuth';

export const EXCEPTION_COMPONENT = () => import('~~/pages/exception.vue');

export const LAYOUT_DEFAULT = () => import('~~/layouts/default.vue');
export const LAYOUT_AUTH = () => import('~~/layouts/auth.vue');
