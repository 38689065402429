import type { GlobalThemeOverrides } from 'naive-ui';

import type { KuliTheme } from '~~/designs/theme/theme.types';

import { addColorAlpha, getRgb } from '~~/designs/colors/color.colord';
import { getColorPalette, getPaletteColorByNumber } from '~~/designs/colors/palette';
import { themeVars } from '~~/designs/theme/theme.vars';

const DARK_CLASS = 'dark';

/**
 * Create theme token
 *
 * @param colors Theme colors
 */
export function createThemeToken(colors: KuliTheme['ThemeColor']) {
  const paletteColors = createThemePaletteColors(colors);

  const themeTokens: KuliTheme['ThemeToken'] = {
    colors: {
      ...paletteColors,
      nprogress: paletteColors.primary,
      container: 'rgb(255, 255, 255)',
      layout: 'rgb(247, 250, 252)',
      inverted: 'rgb(0, 20, 40)',
      base_text: 'rgb(31, 31, 31)',
    },
    boxShadow: {
      header: '0 1px 2px rgb(0, 21, 41, 0.08)',
      sidebar: '2px 0 8px 0 rgb(29, 35, 41, 0.05)',
      tab: '0 1px 2px rgb(0, 21, 41, 0.08)',
    },
  };

  const darkThemeTokens: KuliTheme['ThemeToken'] = {
    colors: {
      ...themeTokens.colors,
      container: 'rgb(28, 28, 28)',
      layout: 'rgb(18, 18, 18)',
      base_text: 'rgb(224, 224, 224)',
    },
    boxShadow: {
      ...themeTokens.boxShadow,
    },
  };

  return {
    themeTokens,
    darkThemeTokens,
  };
}

/**
 * Create theme palette colors
 *
 * @param colors Theme colors
 */
function createThemePaletteColors(colors: KuliTheme['ThemeColor']) {
  const colorKeys = Object.keys(colors) as Array<KuliTheme['ThemeColorKey']>;
  const colorPaletteVar = {} as KuliTheme['ThemePaletteColor'];

  colorKeys.forEach((key) => {
    const colorMap = getColorPalette(colors[key]);

    colorPaletteVar[key] = colorMap.get(500)!;

    colorMap.forEach((hex, number) => {
      colorPaletteVar[`${key}-${number}`] = hex;
    });
  });

  return colorPaletteVar;
}

/**
 * Add theme vars to html
 *
 * @param tokens
 */
export function addThemeVarsToHtml(tokens: KuliTheme['BaseToken'], darkTokens: KuliTheme['BaseToken']) {
  const cssVarStr = getCssVarByTokens(tokens);
  const darkCssVarStr = getCssVarByTokens(darkTokens);

  const css = `
    html {
      ${cssVarStr}
    }
  `;

  const darkCss = `
    html.${DARK_CLASS} {
      ${darkCssVarStr}
    }
  `;

  const styleId = 'theme-vars';

  const style = document.querySelector(`#${styleId}`) || document.createElement('style');

  style.id = styleId;

  style.textContent = css + darkCss;

  document.head.appendChild(style);
}

/**
 * Get css var by tokens
 *
 * @param tokens Theme base tokens
 */
function getCssVarByTokens(tokens: KuliTheme['BaseToken']) {
  const styles: Array<string> = [];

  function removeVarPrefix(value: string) {
    return value.replace('var(', '').replace(')', '');
  }

  function removeRgbPrefix(value: string) {
    return value.replace('rgb(', '').replace(')', '');
  }

  for (const [key, tokenValues] of Object.entries(themeVars)) {
    for (const [tokenKey, tokenValue] of Object.entries(tokenValues)) {
      let cssVarsKey = removeVarPrefix(tokenValue);
      let cssValue = tokens[key][tokenKey];

      if (key === 'colors') {
        cssVarsKey = removeRgbPrefix(cssVarsKey);
        const { r, g, b } = getRgb(cssValue);
        cssValue = `${r} ${g} ${b}`;
      }

      styles.push(`${cssVarsKey}: ${cssValue}`);
    }
  }

  return styles.join(';');
}

/**
 * ------------------ Naive theme ------------------
 */
type NaiveColorScene = '' | 'Active' | 'Hover' | 'Pressed' | 'Suppl';
type NaiveColorKey = `${KuliTheme['ThemeColorKey']}Color${NaiveColorScene}`;
type NaiveThemeColor = Partial<Record<NaiveColorKey, string>>;
interface NaiveColorAction {
  handler: (color: string) => string;
  scene: NaiveColorScene;
}

/**
 * Get naive theme
 */
export function getNaiveTheme(colors: KuliTheme['ThemeColor']) {
  const { primary: colorLoading } = colors;

  const theme: GlobalThemeOverrides = {
    Button: {
      fontWeight: '700',
    },
    LoadingBar: {
      colorLoading,
    },
    common: {
      ...getNaiveThemeColors(colors),
    },
  };

  return theme;
}

/**
 * Get naive theme colors
 */
function getNaiveThemeColors(colors: KuliTheme['ThemeColor']) {
  const colorActions: Array<NaiveColorAction> = [
    { scene: '', handler: (color) => color },
    { scene: 'Suppl', handler: (color) => color },
    { scene: 'Hover', handler: (color) => getPaletteColorByNumber(color, 500) },
    { scene: 'Pressed', handler: (color) => getPaletteColorByNumber(color, 700) },
    { scene: 'Active', handler: (color) => addColorAlpha(color, 0.1) },
  ];

  const themeColors: NaiveThemeColor = {};

  const colorEntries = Object.entries(colors) as Array<[KuliTheme['ThemeColorKey'], string]>;

  colorEntries.forEach((color) => {
    colorActions.forEach((action) => {
      const [colorType, colorValue] = color;
      const colorKey: NaiveColorKey = `${colorType}Color${action.scene}`;
      themeColors[colorKey] = action.handler(colorValue);
    });
  });

  return themeColors;
}
