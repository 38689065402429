import type { KuliI18n } from './locale.types';

export const LOCALE_KEY = 'LOCALE__';

export const LOCALE: Record<string, KuliI18n['LocaleType']> = {
  en: 'en',
};

export const localeSetting: KuliI18n['LocaleConfig'] = {
  // available Locales
  availableLocales: [LOCALE.en],
  // Default locale
  fallback: LOCALE.en,
  // Locale
  locale: LOCALE.en,
};
