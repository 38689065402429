// @unocss-include

import { PAGES_MODULES } from '~~/router/router.entity';

export const MENU_ITEMS = {
  [PAGES_MODULES.ENGAGEMENT]: [
    {
      name: 'create-new',
      label: 'Create New',
      icon: 'icon-gridicons:create',
      children: [
        `${PAGES_MODULES.ENGAGEMENT}/egmt/create`,
        `${PAGES_MODULES.ENGAGEMENT}/activities/create`,
      ],
    },
    `${PAGES_MODULES.ENGAGEMENT}/dashboard`,
    `${PAGES_MODULES.ENGAGEMENT}/egmt`,
    `${PAGES_MODULES.ENGAGEMENT}/activities`,
  ],
};
