import type { Router } from 'vue-router';

import { useTitle } from '@vueuse/core';

import { useI18n } from '~~/locales';

export function createDocumentTitleGuard(router: Router) {
  router.afterEach((to) => {
    const { $t } = useI18n();

    const { i18nKey, title } = to.meta;

    const systemTitle = 'Delta Capita';
    const tTitle = i18nKey ? $t(i18nKey) : title;
    const documentTitle = tTitle ? `${tTitle} - ${systemTitle}` : systemTitle;

    useTitle(documentTitle);
  });
}
