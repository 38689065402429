import type { RouteRecordRaw } from 'vue-router';

import { AUTH_ROUTE_NAME, LAYOUT_AUTH } from '~~/router/router.entity';

const loginRoute: RouteRecordRaw = {
  name: AUTH_ROUTE_NAME,
  path: '/auth',
  redirect: '/auth/login',
  component: LAYOUT_AUTH,
  meta: {
    title: 'Auth',
    ignoreAuth: true,
    hideInMenu: true,
  },
  children: [
    {
      component: () => import('~~/pages/auth/login.vue'),
      path: 'login',
      name: '/auth/login',
      meta: {
        title: 'Login',
        i18nKey: 'routes.auth.login',
      },
    },
  ],
};

export default loginRoute;
