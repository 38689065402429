import type { KuliTheme } from '~~/designs/theme/theme.types';

export const BACKUP_BEFORE_MOBILE_KEY = 'BACKUP_BEFORE_MOBILE__';
export const MIXED_SIDEBAR_FIXED_KEY = 'MIXED_SIDEBAR_FIXED__';

/** Default theme settings */
export const DEFAULT_THEME: KuliTheme['ThemeSetting'] = {
  themeScheme: 'light',

  themeColor: '#D11F7A',

  otherColor: {
    error: '#f5222d',
    info: '#2080f0',
    success: '#52c41a',
    warning: '#faad14',
  },

  isInfoFollowPrimary: false,

  layout: {
    mode: 'vertical',
    scrollMode: 'content',
  },

  page: {
    animate: true,
    animateMode: 'fade-slide',
  },

  header: {
    height: 56,
  },

  fixedHeaderAndTab: true,

  sidebar: {
    collapsedWidth: 64,
    inverted: true,
    mixChildMenuWidth: 200,
    mixCollapsedWidth: 64,
    mixWidth: 90,
    width: 245,
  },

  footer: {
    fixed: false,
    height: 48,
    right: true,
    visible: false,
  },
};
