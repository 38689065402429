import type { RouteRecordRaw } from 'vue-router';

export function sortRoutesByOrder(routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
  routes.sort(routeSorterByOrder);

  routes.forEach(sortChildrenRoutes);

  return routes;
}

function sortChildrenRoutes(route: RouteRecordRaw): RouteRecordRaw {
  if (route.children?.length) {
    route.children.sort(routeSorterByOrder);

    route.children.forEach(sortChildrenRoutes);
  }

  return route;
}

function routeSorterByOrder(next: RouteRecordRaw, prev: RouteRecordRaw): number {
  return (Number(next.meta?.order) || 0) - (Number(prev.meta?.order) || 0);
}

/**
 * Get cache route names
 */
export function getCacheRouteNames(routes: Array<RouteRecordRaw>) {
  const cacheNames: Array<string> = [];

  routes.forEach((route) => {
    // only get last two level route, which has component
    route.children?.forEach((child) => {
      if (child.component && child.meta?.keepAlive) {
        cacheNames.push(child.name as string);
      }
    });
  });

  return cacheNames;
}
