import type { RouteRecordName, RouteRecordRaw } from 'vue-router';

import { defineStore } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';

import { useCoreStore } from '~~/core/core.store';
import { router } from '~~/router';
import { type KuliMenu, getGlobalMenusByAuthRoutes, getSelectedMenuKeyPathByKey, transformMenuToSearchMenus, updateLocaleOfGlobalMenus } from '~~/router/menu';
import { NOT_FOUND_ROUTE_NAME, type PAGES_MODULES } from '~~/router/router.entity';
import { getCacheRouteNames, sortRoutesByOrder } from '~~/router/router.utils';
import { createStaticRoutes } from '~~/router/routes';
import { SETUP_STORE_ID } from '~~/store/store.entity';

export const useRouterStore = defineStore(SETUP_STORE_ID.ROUTER, () => {
  const coreStore = useCoreStore();

  const hasAuthRoutesAdded = ref(false);

  const hasConstantRoutesAdded = ref(false);

  /**
   * Auth route mode
   *
   * It recommends to use static mode in the development environment, and use dynamic mode in the production.
   */
  const authRouteMode = ref('static');

  /** Home route key */
  const routeHome = ref('/');

  const routeObject = computed(() =>
    router.resolve({ path: routeHome.value }),
  );

  /**
   * Selected module
   */
  const selectedModule = ref<PAGES_MODULES | null>(null);

  /** constant routes */
  const constantRoutes = shallowRef<Array<RouteRecordRaw>>([]);

  function addConstantRoutes(routes: Array<RouteRecordRaw>) {
    const constantRoutesMap = new Map<RouteRecordName, RouteRecordRaw>([]);

    routes.forEach((route) => {
      constantRoutesMap.set(route.name!, route);
    });

    constantRoutes.value = Array.from(constantRoutesMap.values());
  }

  /** auth routes */
  const authRoutes = shallowRef<Array<RouteRecordRaw>>([]);

  function addAuthRoutes(routes: Array<RouteRecordRaw>): void {
    const authRoutesMap = new Map<RouteRecordName, RouteRecordRaw>([]);

    routes.forEach((route) => {
      authRoutesMap.set(route.name!, route);
    });

    authRoutes.value = Array.from(authRoutesMap.values());
  }

  const removeRouteFns: Array<() => void> = [];

  /* ------ Menus ------ */
  const menus = ref<Array<KuliMenu>>([]);
  const searchMenus = computed(() => transformMenuToSearchMenus(menus.value));

  /** Get global menus */
  function getGlobalMenus() {
    menus.value = getGlobalMenusByAuthRoutes(selectedModule.value!);
  }

  watch(
    selectedModule,
    () => {
      getGlobalMenus();
    },
  );

  /** Update global menus by locale */
  function updateGlobalMenusByLocale() {
    menus.value = updateLocaleOfGlobalMenus(menus.value);
  }

  /* ------ Cache routes ------ */

  /** Cache routes */
  const cacheRoutes = ref<Array<string>>([]);

  /**
   * Get cache routes
   */
  function getCacheRoutes(routes: Array<RouteRecordRaw>) {
    cacheRoutes.value = getCacheRouteNames(routes);
  }

  /**
   * Add cache routes
   */
  function addCacheRoutes(routeKey: string) {
    if (cacheRoutes.value.includes(routeKey)) {
      return;
    }

    cacheRoutes.value.push(routeKey);
  }

  /**
   * Remove cache routes
   */
  function removeCacheRoutes(routeKey: string) {
    const index = cacheRoutes.value.findIndex((item) => item === routeKey);

    if (index === -1) {
      return;
    }

    cacheRoutes.value.splice(index, 1);
  }

  /**
   * Re-cache routes by route key
   */
  async function reCacheRoutesByKey(routeKey: string) {
    removeCacheRoutes(routeKey);

    await coreStore.reloadPage();

    addCacheRoutes(routeKey);
  }

  /** Reset store */
  async function resetStore() {
    const routeStore = useRouterStore();

    routeStore.$reset();

    resetAllRoutes();

    // after reset store, need to re-init constant route
    await initConstantRoutes();
  }

  /** Reset all routes */
  function resetAllRoutes() {
    removeRouteFns.forEach((fn) => {
      fn();
    });
    removeRouteFns.length = 0;
  }

  /** initialize constant route */
  async function initConstantRoutes() {
    if (hasConstantRoutesAdded.value) {
      return;
    }

    const staticRoute = createStaticRoutes();

    if (authRouteMode.value === 'static') {
      addConstantRoutes(staticRoute.constantRoutes);
    } else {
      // TODO: enable this for dynamic mode route
      // const { data, error } = await fetchConstantRoutes();

      // if (!error) {
      //   addConstantRoutes(data);
      // } else {
      //   // if fetch constant routes failed, use static constant routes
      //   addConstantRoutes(staticRoute.constantRoutes);
      // }
    }

    handleConstantAndAuthRoutes();

    hasConstantRoutesAdded.value = true;
  }

  /** Init auth route */
  async function initAuthRoutes() {
    if (authRouteMode.value === 'static') {
      await initStaticAuthRoute();
    } else {
      await initDynamicAuthRoute();
    }
  }

  /** Init static auth route */
  async function initStaticAuthRoute() {
    const { authRoutes: staticAuthRoutes } = createStaticRoutes();

    addAuthRoutes(staticAuthRoutes);

    handleConstantAndAuthRoutes();

    hasAuthRoutesAdded.value = true;
  }

  /** Init dynamic auth route */
  async function initDynamicAuthRoute() {
    // TODO: implement this when backend route is ready
    // const { data, error } = await fetchGetUserRoutes();

    // if (!error) {
    //   const { routes, home } = data;

    //   addAuthRoutes(routes);

    //   handleAuthRoutes();

    //   setRouteHome(home);

    //   handleUpdateRootRouteRedirect(home);

    //   setIsInitAuthRoute(true);
  }

  /** handle auth routes */
  function handleConstantAndAuthRoutes() {
    const allRoutes = [...constantRoutes.value, ...authRoutes.value];

    const sortRoutes = sortRoutesByOrder(allRoutes);

    resetAllRoutes();

    addRoutesToRouter(sortRoutes);

    getCacheRoutes(sortRoutes);

    const currentRoute = router.currentRoute.value;

    if (currentRoute.path === '/') {
      return;
    }

    if (currentRoute.name === NOT_FOUND_ROUTE_NAME) {
      return;
    }

    const firstPath = currentRoute.path.split('/')[1];

    selectedModule.value = `/${firstPath}` as PAGES_MODULES;
  }

  /**
   * Add routes to vue router
   */
  function addRoutesToRouter(routes: Array<RouteRecordRaw>) {
    routes.forEach((route) => {
      const removeFn = router.addRoute(route);
      addRemoveRouteFn(removeFn);
    });
  }

  /**
   * Add remove route fn
   */
  function addRemoveRouteFn(fn: () => void) {
    removeRouteFns.push(fn);
  }

  /**
   * Get selected menu key path
   *
   * @param selectedKey Selected menu key
   */
  function getSelectedMenuKeyPath(selectedKey: string) {
    return getSelectedMenuKeyPathByKey({ selectedKey, menus: menus.value });
  }

  return {
    hasAuthRoutesAdded,
    hasConstantRoutesAdded,
    cacheRoutes,
    resetStore,
    selectedModule,
    initConstantRoutes,
    updateGlobalMenusByLocale,
    initAuthRoutes,
    getSelectedMenuKeyPath,
    routeHome,
    searchMenus,
    menus,
    reCacheRoutesByKey,
    routeObject,
  };
});
