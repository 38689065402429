// @unocss-include

export function renderAppLoader() {
  const loadingHtml = `
  <div class="fixed-lt wh-full flex-center flex-col">
    <img
      style="height: 150px"
      src="/logo.svg"
      alt="Logo"
    >
  
    <i class="icon-line-md:loading-twotone-loop my-36px h-56px w-56px color-primary">
    </i>
  </div>
  `;
  const app = document.getElementById('app');

  if (app) {
    app.innerHTML = loadingHtml;
  }
}
