import type { RouteRecordRaw } from 'vue-router';

import { LAYOUT_DEFAULT, PAGES_MODULES } from '~~/router/router.entity';

const engagementRoute: RouteRecordRaw = {
  path: `${PAGES_MODULES.ENGAGEMENT}/egmt`,
  name: '/egmt-root',
  component: LAYOUT_DEFAULT,
  children: [
    {
      path: '',
      name: `${PAGES_MODULES.ENGAGEMENT}/egmt`,
      component: () => import('~~/pages/engagements/egmt/index.vue'),
      meta: {
        i18nKey: 'routes.egmt.index',
        icon: 'icon-ph:chats',
      },
    },
    {
      path: 'create',
      name: `${PAGES_MODULES.ENGAGEMENT}/egmt/create`,
      component: () => import('~~/pages/engagements/egmt/create.vue'),
      meta: {
        i18nKey: 'routes.egmt.create',
      },
    },
    {
      path: ':id',
      name: `${PAGES_MODULES.ENGAGEMENT}/egmt/:id`,
      component: () => import('~~/pages/engagements/egmt/[id].vue'),
      meta: {
        i18nKey: 'routes.egmt.details',
      },
    },
    {
      path: ':id/create-activity',
      name: `${PAGES_MODULES.ENGAGEMENT}/egmt/:id/create`,
      component: () => import('~~/pages/engagements/egmt/create-activity.vue'),
      meta: {
        i18nKey: 'routes.activities.create',
      },
    },
  ],
};

export default engagementRoute;
