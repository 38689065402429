import type { RouteLocation } from 'vue-router';

import { isString } from '@vinicunca/perkakas';

import type { KuliMenu } from '~~/router/menu/menu.types';
import type { PAGES_MODULES } from '~~/router/router.entity';

import CoreSvgIcon from '~~/core/components/core-svg-icon.vue';
import { useSvgIconRender } from '~~/core/composables/use-svg-icon';
import { useI18n } from '~~/locales';
import { router } from '~~/router';
import { MENU_ITEMS } from '~~/router/menu/menu.entity';

/**
 * Get layout menus by auth routes
 *
 */
export function getGlobalMenusByAuthRoutes(selectedModule: PAGES_MODULES) {
  const { SvgIconVNode } = useSvgIconRender(CoreSvgIcon);

  return MENU_ITEMS[selectedModule].map((menuItem) => {
    if (isString(menuItem)) {
      return resolveRouteToMenu(menuItem);
    }

    return {
      key: menuItem.name,
      label: menuItem.label,
      icon: SvgIconVNode({ icon: menuItem.icon, fontSize: 20 }),
      children: menuItem.children.map((child) => resolveRouteToMenu(child)),
      routeKey: menuItem.name,
      routePath: menuItem.name,
    } satisfies KuliMenu;
  });
}

function resolveRouteToMenu(routeName: string) {
  const route = router.resolve({ name: routeName });
  return getLayoutMenuByBaseRoute(route);
}

/**
 * Get layout menu by route
 */
export function getLayoutMenuByBaseRoute(route: RouteLocation) {
  const { SvgIconVNode } = useSvgIconRender(CoreSvgIcon);
  const { $t } = useI18n();

  const { path } = route;
  const { title, i18nKey, icon, localIcon } = route.meta ?? {};

  const label = i18nKey ? $t(i18nKey) : title!;

  const menu: KuliMenu = {
    key: route.name as string,
    label,
    i18nKey,
    routeKey: route.name as string,
    routePath: path,
    icon: SvgIconVNode({ icon, localIcon, fontSize: 20 }),
  };

  return menu;
}

/**
 * Update locale of global menus
 */
export function updateLocaleOfGlobalMenus(menus: Array<KuliMenu>) {
  const { $t } = useI18n();

  const result: Array<KuliMenu> = [];

  menus.forEach((menu) => {
    const { i18nKey, label, children } = menu;

    const newLabel = i18nKey ? $t(i18nKey) : label;

    const newMenu: KuliMenu = {
      ...menu,
      label: newLabel,
    };

    if (children?.length) {
      newMenu.children = updateLocaleOfGlobalMenus(children);
    }

    result.push(newMenu);
  });

  return result;
}

/**
 * Get selected menu key path
 */
export function getSelectedMenuKeyPathByKey(
  { menus, selectedKey }:
  { menus: Array<KuliMenu>; selectedKey: string },
) {
  const keyPath: Array<string> = [];

  menus.some((menu) => {
    const path = findMenuPath({
      targetKey: selectedKey,
      menu,
    });

    const find = Boolean(path?.length);

    if (find) {
      keyPath.push(...path!);
    }

    return find;
  });

  return keyPath;
}

/**
 * Find menu path
 */
function findMenuPath(
  { targetKey, menu }:
  { menu: KuliMenu; targetKey: string },
): Array<string> | null {
  const path: Array<string> = [];

  function dfs(item: KuliMenu): boolean {
    path.push(item.key);

    if (item.key === targetKey) {
      return true;
    }

    if (item.children) {
      for (const child of item.children) {
        if (dfs(child)) {
          return true;
        }
      }
    }

    path.pop();

    return false;
  }

  if (dfs(menu)) {
    return path;
  }

  return null;
}

/**
 * Transform menu to searchMenus
 */
export function transformMenuToSearchMenus(menus: Array<KuliMenu>, treeMap: Array<KuliMenu> = []) {
  if (menus && menus.length === 0) {
    return [];
  }
  return menus.reduce((acc, cur) => {
    if (!cur.children) {
      acc.push(cur);
    }
    if (cur.children && cur.children.length > 0) {
      transformMenuToSearchMenus(cur.children, treeMap);
    }
    return acc;
  }, treeMap);
}
