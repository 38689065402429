import type { RouteRecordRaw } from 'vue-router';

const modules = import.meta.glob('./modules/**/*.ts', { eager: true });
const routeModuleList: Array<RouteRecordRaw> = [];

Object.keys(modules).forEach((key) => {
  const mod = (modules as Record<string, any>)[key].default || {};
  const modList = Array.isArray(mod) ? [...mod] : [mod];
  routeModuleList.push(...modList);
});

export function createStaticRoutes() {
  const constantRoutes: Array<RouteRecordRaw> = [];

  const authRoutes: Array<RouteRecordRaw> = [];

  routeModuleList.forEach((item) => {
    if (item.meta?.ignoreAuth) {
      constantRoutes.push(item);
    } else {
      authRoutes.push(item);
    }
  });

  return {
    authRoutes,
    constantRoutes,
  };
}
